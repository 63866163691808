import(/* webpackMode: "eager", webpackExports: ["PrismicNextImage"] */ "/app/node_modules/@prismicio/next/dist/PrismicNextImage.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicPreviewClient"] */ "/app/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicToolbar"] */ "/app/node_modules/@prismicio/react/dist/PrismicToolbar.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_components/funding-progress-bar-complete-info/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_components/icon-tile.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/_components/ui/accordion.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/_components/ui/button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(business)/client-page.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/lib/translation/components/link/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/slices/Carousel/_components/carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/slices/CurrentProjects/_components/carousel/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/slices/Hero/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/slices/NewsCarousel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/slices/PressArticles/client.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/slices/Summary/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/slices/Testimonials/_components/carousel/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/slices/TypeForm/index.tsx");
